.formikSelectSign {
  -webkit-appearance: none;
  background-image: url("../../Assets/selectArrow.png");
  background-size: 15px 8px !important;
  background-repeat: no-repeat;
  background-size: 4%;
  background-position: top 50% right 6%;
}

.css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
}

.login-dancer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  width: 421px;
  height: auto;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 10px 22px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.login-dancer .MuiSvgIcon-root {
  margin-left: -8px !important;
}

.css-sjumqa-MuiButtonBase-root-MuiButton-root:hover {
  border: 0px solid !important;
  background: #fbb040;
  color: #ffffff;
}

.makeVisible {
  background-color: black;
}
@media only screen and (min-width: 0px) and (max-width: 700px) {
  .recvrNext {
    font-size: 16px !important;
  }
}
@media only screen and (min-width: 760px) and (max-width: 1200px) {
  .recvrNext {
    font-size: 18px !important;
  }
}

.makeInvisible {
  opacity: 0.3;
}

.plusIconbtn:hover #plusICon {
  visibility: hidden !important;
  width: 0px;
  transition-property: width;
  transition-duration: 0.3s;
  margin: auto;
}

.plusIconbtn .MuiSvgIcon-root {
  margin: 0 5px 0 0;
}
.login-dancer .MuiSvgIcon-root {
  margin-left: 0px !important;
}
.add_dance_btn :hover {
  cursor: auto !important;
}

.css-sjumqa-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: white !important;
}

.css-sjumqa-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 11px;
  gap: 8px;
  width: 192px;
  height: 42px;
  background: #68ad3f !important;
  color: white !important;
  opacity: 0.3 !important;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.css-sjumqa-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: white !important;
  opacity: 0.5 !important;
}

#selectField select :option {
  background-color: black !important;
  border-radius: 2px solid black !important;
}

.AddDancernme {
  font-size: 16px;
}

.formikSelect option {
  color: black;
}
#completeRegistrationbtn{
  padding: 7px 8px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* Safari and Chrome */
  .formikSelect option {
    color: black;
  }

  .deleteIcon {
    color: black;
  }
}

.helpImageDiv {
  position: fixed;
  bottom: 3vw;
  right: 3vw;
}

.formikSelect {
  height: 42px;
}

.allDancers {
  width: 80%;
  margin: 8px;
  border-radius: 8px;
  box-shadow: 8px 8px 34px rgb(0 0 0 / 5%);
}
.login-dancer .css-46bh2p-MuiCardContent-root {
  padding: 15px 16px;
}
.title-signup {
  color: #323232;
}

.email-form {
  width: 300px;
  display: flex;
  flex-direction: column;
}

label {
  font-weight: 500;
  margin-left: 5px;
}

input[type="checkbox"]::before {
  height: 20px;
  width: 20px;
  padding: 10px 0px 10px 0px;
}

input [type="select"]::after {
  border-radius: "45px";
}

.forgot-password {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #68ad3f;
  font-weight: 500;
}

.sign-up-btn {
  cursor: pointer;
  color: #68ad3f;
  font-weight: 500;
}

/* <---------------------------- CSS for SignUp Page -------------------------------------> */
.signupmain {
  width: 530px;
  height: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 22px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: auto;
}

.signUpTitle {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 120%;
  color: #323232;
}

.signUpForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sign-up-form input {
  width: 100%;
}

.subtitle-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.role-name {
  cursor: pointer;
  display: flex;
  color: #424242;
  font-size: 14px;
  font-weight: 400;
  width: 190px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 15px 28px rgb(0 0 0 / 5%);
  border-radius: 4px;
  align-items: center;
  padding: 10px;
  margin: 15px;
}

.role-name:hover {
  transform: translateY(-10px);
  transition: transform 500ms;
  box-shadow: 0px 12px 27px rgba(0, 0, 0, 0.15);
}

.role {
  margin: 0px 0px 0px 10px;
}

/* <---------------------------- CSS for SignUp Page -------------------------------------> */

/* <---------------------------- media query for mobile devices -------------------------------------> */
@media only screen and (min-width: 0px) and (max-width: 330px) {
  .form {
    width: 250px;
  }
  .allDancers {
    width: 100%;
  }
}

/* <---------------------------- media query for mobile devices -------------------------------------> */
@media only screen and (min-width: 331px) and (max-width: 560px) {
  .form {
    width: 300px;
  }

  .forgot-password {
    height: 200px;
  }
  .allDancers {
    width: 100%;
  }
}

.sign-up-forgot-pwd-div {
  color: #060705;
  font-size: 14px;
}

.sign-up-forgot-pwd-btn {
  cursor: pointer;
  color: #323232;
}

.email-password {
  padding: 10px;
}

.title-signin {
  color: #323232;
  font-weight: 500;
  font-size: 22px;
}

.email-form-signIn {
  width: 530px;
  height: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 22px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.login-form-signIn {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* <---------------------------- CSS for signIn Page -------------------------------------> */

/* <---------------------------- CSS for ResetPassword Page -------------------------------------> */
.email-form-reset {
  width: auto;
  height: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 22px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

/* <---------------------------- CSS for ResetPassword Page -------------------------------------> */
.reset-pwd-login-button {
  background: none;
  border: none;
  cursor: pointer;
}

.email-password-reset {
  width: "450px";
  height: "39px";
}

/* <---------------------------- media query for mobile devices -------------------------------------> */
@media only screen and (min-width: 0px) and (max-width: 319px) {
  .form {
    width: 250px;
  }

  /*--------------signIn--------------*/
  .email-form-signIn {
    width: 300px;
  }

  .sign-up-forgot-pwd-div {
    color: #060705;
    font-size: 10px;
  }

  /*--------------signIn--------------*/
}

/* <---------------------------- media query for mobile devices -------------------------------------> */
@media only screen and (min-width: 320px) and (max-width: 560px) {
  .form {
    width: 300px;
  }
 
  .forgot-password {
    height: 200px;
  }
  .login-dancer {
    width: 300px;
  }

  /*--------------signIn--------------*/
  .email-form-signIn {
    position: relative;
    width: 300px;
    height: auto;
    z-index: 99;
  }

  .sign-up-forgot-pwd-div {
    color: #060705;
    font-size: 10px;
  }

  /*--------------signIn--------------*/
  /*--------------resetPassword--------------*/
  .title-resetPwd {
    font-size: 18px;
  }

  .subtitle-resetPwd {
    font-size: small;
  }

  /*--------------resetPassword--------------*/
}

.css-m7ed81.Mui-disabled {
  background: #68ad3f !important;
  color: white !important;
  opacity: 0.3 !important;
}

.change-password-btn .Mui-disabled {
  color: white !important;
  opacity: 0.3 !important;
}

.css-199mpmr {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-transform: none;
  font-family: Rubik;
  font-weight: 500;
  line-height: 1.75;
  min-width: 64px;
  padding: 6px 25px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgb(104, 173, 63);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  color: white;
  height: 39px;
  font-size: 16px;
  -webkit-box-pack: center;
  justify-content: center;
}

.addDAncers {
  width: 300px;
  height: 100px;
  color: black;
  transition-property: width;
  transition-duration: 1s;
}

.addDAncers:hover {
  width: 100px;
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .login-dancer {
    width: 500px;
  }
}

@media only screen and (min-width: 1900px) and (max-width: 1930px) {
  .login-dancer .MuiTypography-root {
    margin: 0 !important;
    font-family: Rubik !important;
    font-weight: 400 !important;

    line-height: 1.5 !important;
    margin-bottom: 0.35em !important;
    max-width: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .signUpAlr {
    /* margin-bottom: 3rem !important; */
    margin-top: 3rem !important;
  }
}
@media screen and (min-width: 1900px) {
  .login-dancer .MuiSvgIcon-root {
    margin-left: -6px !important;
  }
  #ADDdANCER:hover{
    margin-left: 5px !important;
  }
}
@media screen and (max-width:700px) {
  .formikSelectSign {
    -webkit-appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAYCAYAAACMcW/9AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACnSURBVHgBzdfJDcAgDARAd04JlOASKYEEEn8SQjh8rWSJB2JHiA8AV8I56Z4I9ml6yiI/BsEuCG9PgVd1Bh9Y/LCkHlQbix1HhYbOBi0s/hji6EZJ7HS3BXa5UxO73aWBZeuQxLKfLYEVuwDOg8WfFEeBOJKjSA25U6iOXCk2Q84AzJGjWBfIHaw6kjKDNUNSRrDmSEoP6wZJaWHdISnly0Df3ACMOQATckHecQwCXQAAAABJRU5ErkJggg==);
    background-size: 12px 7px !important;
    background-repeat: no-repeat;
    background-size: 4%;
    background-position: top 50% right 8%;
}
.helpImageDiv img{
  height: 46px;
}
}