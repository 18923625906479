.infinite-scroll-component {
overflow: visible !important;
}

.register-events {
  width: 100%;
  box-shadow: 0px 15px 42px rgba(0, 0, 0, 0.05);
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  margin-bottom: 32px;
  margin-top: 20px !important;
  cursor: pointer;
}
.event-mobile {
  display: none;
}
.web-events-list {
  display: flex;
  align-items: center;
  width: 100%;
}
.event-details {
  width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.event-img {
  height: 100px;
  width: 100px;
  padding: 11px;
  margin-left: -11px;
}
.title {
  color: #68ad3f;
  font-weight: 500;
  font-size: 14px;
}

.event-name {
  font-size: 20px;
  color: #424242;
}
.reg-date {
  margin-bottom: 3px;
  color: #797979;
  font-size: 16px;
}
.reg-date-open{
  margin-bottom: 3px;
  color: #3f3f3f;
  font-size: 16px;
  opacity: 37%;
}
.reg-date-close {
  color: #fbb040;
  font-size: 14px;
  text-align: end;
}
.reg-name {
  text-align: end;
}
.registrationDetails {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 17px;
}


/* <---------------------------- media query for mpbile devices -------------------------------------> */
@media only screen and (min-width: 0px) and (max-width: 480px) {

  .register-events {
    padding: 20px 0px 20px 0px;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
  }
  .register-events-web{
display:none !important;
  }
  .event-mobile {
    width: 100%;
    display: block;
  }
  .event-name {
    font-size: 12px;
  }
  .web-btn {
    display: none !important;
  }
  .event-mobile-div {
    display: flex;
    align-items: center;
    padding: 0px 20px 0px 20px;
  }
  .web-events-list {
    display: none;
  }
  .event-img {
    height: 50px;
    width: 50px;
    padding: 2px;
  }
  .reg-date, .reg-date-open {
    font-size: 12px !important;
    margin-top: 5px;
  }
  
  .reg-name {
    padding: 0px 20px 0px 20px;
  }
  .reg-close {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eaeaea;
    width: 100%;
    padding: 16px 20px 0px 20px;
  }
  .event-details {
    width: 100%;
  }
  .reg-date-mobile {
    font-size: 12px;
  }
  .reg-date-close {
    font-size: 11px;
    text-align: start;
  }
  .mobile-registration {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .title {
    font-size: 10px;
  }
  .mob-register-btn {
    width: 122px !important;
  }
}

/* <---------------------------- media query for mpbile devices landscape orientation -------------------------------------> */
@media only screen and (min-width: 481px) and (max-width: 767px) {

  .event-mobile {
    width: 100%;
    display: block;
  }
  .register-events,.register-events-web {
    padding: 20px 0px 20px 0px;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
  }
  .event-name {
    font-size: 16px !important;
  }
  .web-btn {
    display: none !important;
  }
  .event-mobile-div {
    display: flex;
    align-items: center;
    padding: 0px 20px 0px 20px;
  }
  .web-events-list {
    display: none;
  }
  .event-img {
    height: 50px;
    width: 50px;
    padding: 2px;
  }
  .reg-date, .reg-date-open{
    font-size: 12px;
  }
  .reg-name {
    padding: 0px 20px 0px 20px;
  }
  .reg-close {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eaeaea;
    width: 100%;
    padding: 16px 20px 0px 20px;
  }
  .event-details {
    width: 100%;
  }
  .reg-date-mobile {
    font-size: 13px;
  }
  .reg-date-close {
    font-size: 12px;
  }
  .mobile-registration {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .title {
    font-size: 13px;
  }
  .mob-register-btn {
    width: 122px !important;
  }
}

/* <---------------------------- media query for ipad devices -------------------------------------> */
@media only screen and (min-width: 768px) and (max-width: 980px) {
  .event-details {
    width: 257px !important;
  }
  .event-name {
    font-size: 15px;
  }
  .reg-date, .reg-date-open {
    font-size: 13px !important ;
  }
  .reg-date-close {
    font-size: 9px;
  }
  .title {
    font-size: 12px;
  }
}
/* <---------------------------- media query for ipad pro devices -------------------------------------> */
@media only screen and (min-width: 981px) and (max-width: 1190px) {
  .event-details {
    width: 444px;
  }
  .event-name {
    font-size: 16px;
  }

  .registration-div {
    flex-grow: 1;
  }
  .create-event h2 {
    font-size: 33px !important;
  }
}
.reg-date{
 font-size:15px;
}
 .reg-date-open {
  font-size: 14px;
}
@media only screen and (min-width: 1190px) and (max-width: 1400px) {
  .event-details {
    width: 605px;
  }
  .cardspacing {
    padding: 30px 0px !important;
  }
}
/* <---------------------------- media query for large screens -------------------------------------> */
@media screen and (min-width: 1200px) {
  .event-details {
    flex-grow: 1;
  }
  .register-events {
    padding: 15px 40px;
  }
  .register-eventsEdit{
    padding: 10px 40px;
  }
  .cardspacing {
    padding: 0px 0px 46px 0px !important;
  }
  .devincornell {
    padding: 0px 0px 27px 0px !important;
  }
  .cardSection{
    padding: 35px 0px !important;
  }
  
}

@media screen and (min-width: 1900px) {
  .devincornell {
    padding: 9px 0px 89px 0px !important;
  }
  .cardspacing {
    padding:  0px !important;
  }
}
