.table td {
  vertical-align: middle !important;
}

.back {
  font-size: 16px;
  font-weight: 500;
}
.event-img-view {
  width: 60px;
  height: 60px;
  margin-left:7px;
  margin-right: 5px;
}
.accomdation-modal .MuiBox-root:focus-within {
  outline: none !important;
}

.searchc {
  width: 100%;
  padding: 8px 10px;
  margin-right: -4px;
  outline: none;
  border-width: 0px;
  border-radius: 5px;
}

.register-eventEdit {
  width: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 1px 10px 20px 10px;
  margin-bottom: 32px;
  margin-top: 20px !important;
}

.view-header {
  background-color: #ffffff;
  border: 1.89372px solid rgba(140, 174, 120, 0.12);
  padding: 1% 12% 0.5%;
}
.rounded-top {
  background-color: #eaeaea;
}
.AccomodationHeader {
  background: #ffffff;
  box-shadow: 0px 15px 28px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.view-body {
  padding: 0% 12% 5%;
}

.edit-Print {
  color: #68ad3f;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
.login-ft-btnclosed {
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  border-radius: 4px;
  color: #68ad3f;
  border: 1px solid #68ad3f;
  width: auto;
  height: 42px;
}
.editArrow {
  margin-left: 13px !important;
}
.signup-btnclosed {
  margin: 0px 12px;
  font-weight: 500;
  background-color: transparent;
  border-radius: 4px;
  color: #68ad3f;
  border: 1px solid #68ad3f;
  width: auto;
  height: 42px;
}

.EditCompS {
  border: 2px solid #68ad3f !important;
  border-radius: 4px !important;
  width: auto !important;
}
.register-eventsforClosed {
  width: 100%;
  box-shadow: 0px 15px 42px rgba(0, 0, 0, 0.05);
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 7px 20px;
  margin-bottom: 40px;
  margin-top: 20px;
  cursor: pointer;
  box-sizing: border-box;
  width: 312px;
  height: 63px !important;
  background: #ffffff;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  height: auto;
  justify-content: center;
}
.registerdancerforclosed {
  font-weight: 500;
  font-size: 22px;
  margin-top: 4px;
  color: #424242;
}
.editGradeLvlcls {
  font-weight: 500;
  font-size: 12px;
  opacity: 0.3;
}

.css-s9a7ft-MuiButtonBase-root-MuiButton-root {
  width: auto !important;
}
.EditComp {
  border: 3px solid;
}

.iframe-view {
  width: 100%;
  height: 30vh;
}

.reg-details {
  margin-right: 10px;
}

.adjudicator {
  display: flex;
  padding: 0 0 12px 0;
}
.adjudicatorDescription {
  padding: 12px 0px 0px 8px;
  font-weight: 400px !important;
}

.web-btn {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #68ad3f;
}
.web-btn-edit {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #68ad3f;
  cursor:pointer;
}

.Adjudicators {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.register-eventss {
  height: 150px;
  width: 100%;
  box-shadow: 0px 15px 42px rgba(0, 0, 0, 0.05);
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 20px;
  margin-bottom: 40px;
}

.location-Details {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2d2d2d;
  text-transform: capitalize;
  margin: 0 !important;
}
.adjudicatorDescription h6 {
  color: #424242;
}
.adjudicatorDescription h6,
.adjudicatorDescription p {
  padding: 0;
  margin: 0;
}

.leftBlock {
  height: 90vh;
  overflow-y: scroll;
}

.leftBlock::-webkit-scrollbar {
  display: none;
}

.registerweb-events-list {
  display: flex;
  align-items: center;
  width: 70%;
  flex-grow: 1;
}

.editGrades {
  background-color: #797979;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 6px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  margin-bottom: 3px;
}
.boxScroll::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}
.editGradeLvl {
  font-weight: 500;
  font-size: 12px;
  color: #fbb040;
  opacity: 0.6;
}

.tableCount {
  background-color: #fbb040;
  padding: 0 6px;
  font-size: 16px;
  color: white;
  font-weight: 400;
  border-radius: 4px;
}

.eventGradename {
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #424242;
}

.registerevent-mobile-div {
  display: flex;
}

p.reg-date {
  padding-left: 0px;
}

.pageHeaders {
  margin-top: 20px;
  margin-bottom: 5px;
  color: #424242;
  font-weight: 500;
  font-size: 22px;
}
.editComps {
  padding: 4px !important;
}
.editComps {
  padding: 8px 16px !important;
}
.my_table {
  border-collapse: separate;
  border-spacing: 0;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.my_table tr:first-of-type td:first-of-type {
  border-top-left-radius: 4px;
}

.my_table tr:first-of-type td:last-of-type {
  border-top-right-radius: 4px;
}

.my_table tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 4px;
}

.my_table tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 4px;
}
/*  <---------------------------- media query for mpbile devices ------------------------------------->   */
@media only screen and (min-width: 0px) and (max-width: 481px) {
  .edit-Print {
    font-size: 11px;
  }
  .EditComp {
    font-size: 11px !important;
  }

  .EditCompS {
    font-size: 11px !important;
    padding: 2px !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {

  .web-btn-edit,.delete-btn-dancer,.eventGradename {
    font-size: 12px;
  }
  .editGrades,.editGradeLvl {
    font-size: 10px;
  }
  .back {
    margin-top: 1.65px;
  }
  .competitorTable {
    width: 1100px !important;
  }

  .adjudicator {
    padding: 1vh 0vh 1vh 0vh;
  }

  .Adjudicators {
    display: block;
  }

  .leftBlock {
    height: max-content;
    overflow-y: none;
  }

  .registerweb-events-list {
    display: none;
  }

  img.event-img {
    height: 70px;
    width: 70px;
    padding: 0px;
  }

  .reg-close {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eaeaea;
    width: 100%;
    padding: 20px 20px 0px 20px;
  }

  .reg-date-close {
    text-align: left;
  }

  .header-mobile {
    flex-direction: column;
  }

  .view-body {
    padding: 2% 9%;
  }

  .view-header {
    padding: 1% 5% 0.5%;
  }
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #2d2d2d;
  font-family: Rubik;
}
pre {
  white-space: pre-wrap;
}

@media only screen and (min-width: 481px) and (max-width: 786px) {
  .Adjudicators {
    display: flex;
  }
}

/*  <---------------------------- media query for mpbile devices landscape orientation ------------------------------------->  */
@media (max-width: 561px) {
  .adjudicator {
    padding: 1vh 0vh 1vh 0vh;
  }

  .Adjudicators {
    display: block;
  }

  .leftBlock .adjudicatorCard {
    width: 100% !important;
  }

  .view-btn {
    width: 120px !important;
  }
}

@media (min-width: 561px) {
  .adjudicator {
    width: 48.5%;
  }
}

/*  <---------------------------- media query for ipad devices ------------------------------------->  */

@media only screen and (min-width: 768px) and (max-width: 980px) {
  .adjudicator {
    display: flex;
    padding: 0 8px 8px 0px;
  }

  .view-body {
    padding: 2% 5%;
  }

  .view-header {
    padding: 1% 5% 0.5% !important;
  }

  .registerevent-details {
    width: 279px;
  }
  .registerevent-details-edit {
    width: 250px;
  }
  .competitorTable {
    width: 1100px !important;
  }
  .web-btn-edit{
    font-size: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 980px) {
  .registerevent-details-edit {
    width: 300px;
  }
}
/*  <---------------------------- media query for ipad pro devices ------------------------------------->  */
@media only screen and (min-width: 981px) and (max-width: 1199px) {
  .adjudicator {
    display: flex;
    padding: 1vh;
  }

  .Adjudicators {
    display: flex;
  }
  .registerevent-details {
    width: 397px;
  }
  .registerevent-details-edit {
    width: 300px;
  }
  .competitorTable {
    width: 1100px !important;
  }
}

/*  <---------------------------- media query for large screens ------------------------------------->  */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .registerevent-details {
    width: 75%;
    flex-grow: 1;
  }
  .registerevent-details-edit {
    width: 500px;
  }
}
@media screen and (min-width: 1200px) {
  .eventGradename {
    font-weight: 400;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #424242;
  }
}

