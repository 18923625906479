.page-loader,.page-loader-payment {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.page-loader i {
  font-size: 70px;
  color: rgb(255, 0, 0);
}

.page-loader-payment{
  background-color: rgba(255, 255, 255, 0.75);
}
