.finalPlace{
  font-weight: 500;
  font-size: 20px;
  }
  .result-accordion-item{
    background-color: transparent !important;
  }
  .result-accordion .accordion-button::after{
    display: none;
  }
  .comp-code{
    color:#68ad3f;
    font-size: 18px;
  }
  
  .result-accordion button{
    background-color: #FAFAFA !important;
    display: flex;
    justify-content: space-between;
    padding-left:0 !important;
    padding-right:0 !important;
    padding-bottom: 1px !important;
  border-bottom: 1px solid rgb(204, 198, 198);
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius:0 !important;
  }
  .result-accordion button:focus{
  border-bottom: 1px solid rgb(204, 198, 198) !important;
  }
  .comment-box-div{
    max-height:500px;
    overflow-y:scroll;
  }
.nineJudgeHead{
  writing-mode: vertical-lr;
  -webkit-writing-mode: vertical-lr;
  transform: rotate(180deg);
  display:flex
}

.resultSelect,.resultInput {
  height: 60px;
  font-size: 16px;
  -webkit-appearance: none;
  background-image: url('../../Assets/selectArrow.png');
  background-repeat: no-repeat;
  background-size: 4.2%;
  background-position: top 50% right 7%;
  border: 1px solid #68ad3f !important;
  width: 300px;
  color: #424242;
  padding: 0px 15px !important;
}
.resultInput{
  background: none !important;
  background-color: white !important;
  border-radius: 4px;
}
.resultInput:focus-visible{
  outline:none !important;
}
.event-judge-name {
  font-weight: 500;
  color: #68ad3f;
}

.event-points {
  color: #424242;
}

.event-comments {
  font-size: 18px;
  color: #424242;
}

.dancer-count {
  font-size: 18px;
  color: #000000;
}

.table1 tr:first-child td,
.table1 tr:nth-child(2) td,
.table3 tr:first-child td,
.table3 tr:nth-child(2) td {
  color: #424242;
}

.table1 td,
.table3 td {
  color: #303030;
}

.golive-section {
  left: 0;
  right: 0;
  overflow-y: auto;
  top: 239px;
  height: calc(100vh - 239px);
  background: #fafafa;
}

.golive-section::-webkit-scrollbar {
  display: none !important;
}
.mob-result-card{
  display:none;
}


@media only screen and (min-width: 0px) and (max-width: 767px) {
  .web-result-card{
    display:none
  }
  .mob-result-card{
    display:block
  }
  .event-judge-name {
    font-size: 16px;
  }
  .event-judge-name-mob{
    font-size:18px;
    color:#68ad3f;
  }
  .judge-final-score-mob{
    font-size:18px;
    color:#FBB040;
  }

  .event-points {
    font-size: 15px;
  }

  .event-comments {
    font-size: 12px;
  }

  .dancer-count {
    font-size: 16px;
    margin-top: 20px;
  }

  .table1 td {
    font-size: 9px;
  }

  .result-btns {
    font-size: 12px !important;
    margin-top: 5px !important;
  }
  .comp-code{
    font-size:15px !important;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1284px) {
  .event-judge-name {
    font-size: 14px;
  }
  .event-points {
    font-size: 13px;
  }

  .event-comments {
    font-size: 14px;
  }

  .dancer-count {
    font-size: 16px;
  }

  .table3 td {
    font-size: 10px;
  }
}

.table1,
.table3 {
  border-collapse: separate;
  border-spacing: 0;
  border: 0.5px solid #dcdcdc;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.table1,
.table3 tr:first-of-type td:first-of-type {
  border-top-left-radius: 4px;
}

.table1,
.table3 tr:first-of-type td:last-of-type {
  border-top-right-radius: 4px;
}

.table1,
.table3 tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 4px;
}

.table1,
.table3 tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 4px;
}
