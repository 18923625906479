.filled{
  background: #68AD3F;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  padding: 8px 11px;
  border:2px solid #68AD3F
}

.orangeFilled{
  background: #FBB040;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  padding: 8px 11px;
border: 2px solid #FBB040;
}

.filled:hover{
background: #FBB040;
border: 2px solid #FBB040;

}
.text-btn{
font-weight: 500;
font-size: 16px;
color: #68AD3F;
border:none;
background-color: transparent;
cursor:pointer;
}

.text-btn-disabled{
  font-weight: 500;
font-size: 16px;
color: #68AD3F;
border:none;
background-color: transparent;
  opacity:0.3;
  pointer-events: none;
}

.outlined{
padding:8px 11px;
font-weight: 500;
background: transparent;
font-size: 16px;
color: #68AD3F;
border: 2px solid #68AD3F;
border-radius: 4px;
box-shadow: none;
}

.outlined:hover{
border: 2px solid #FBB040;
background: #FBB040;
color: #FFFFFF;
}

.disabledOutlined{
padding:8px 11px;
font-weight: 500;
background: transparent;
font-size: 16px;
color: #68AD3F;
border: 2px solid #68AD3F;
border-radius: 4px;
opacity:0.3;
pointer-events: none;
}

.disabled{
  opacity:0.3;
  pointer-events:none;
  background: #68AD3F;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  padding: 8px 11px;
  outline: none;
  border: 2px solid #68AD3F;
  border-radius: 4px;
pointer-events: none;
}

.orangeFilledDisabled{
  opacity:0.3;
  background: #FBB040;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  padding: 8px 11px;
border: 2px solid #FBB040;
pointer-events: none;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
.filled,.outlined,.disabled,.disabledOutlined{
  font-size: 14px;
  padding: 4px 6px;
}

}
@media only screen and (min-width: 768px) and (max-width: 981px) {
.text-btn-tab{
font-size: 14px;
}
}
