footer.csss .background {
  height: 237px;
  width: 100%;
  z-index: -1;
  position: absolute;
  left: 0px;
  background-color: #424242 !important;
}

.footerContainer {
  padding: 3% 12%;
}

.login-ft-btn {
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  border-radius: 4px;
  color: #68ad3f;
  border: 3px solid #68ad3f;
  width: 80px;
  height: 42px;
}

.login-ft-btn:hover {
  background-color: #fbb040;
  border: 2px solid #fbb040 !important;
  color: white;
}

.signup-btn:hover {
  background-color: #fbb040;
  color: white;
}

.signup-btn {
  font-size: 16px;
  font-weight: 500;
  background-color: #68ad3f;
  border-radius: 4px;
  color: white;
  border: none;
  margin-left: 28px;
}

.link {
  color: white;
  font-size: 18px;
}

.footer-bottom div {
  margin-left: 2%;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  margin: 2% 0;
}

.logsignbtns button {
  padding: 8px 11px;
  width: unset !important;
  height: unset !important;
}

@media (max-width: 481px) {
  .signup-btn {
    width: 70px !important;
    height: auto !important;
    font-size: 12px !important;
    margin-left: 12px !important;
  }

  .login-ft-btn {
    width: 70px !important;
    height: auto !important;
    font-size: 12px !important;
    padding: 8px !important;
    border: 4px solid;
  }

  .link {
    font-size: 12px;
  }

  .background {
    height: 130px !important;
  }

  .footer-top {
    margin: 6% 0;
  }

  .footer-top img {
    width: 85%;
  }
}

@media (max-width: 1024px) {
  .footer-top {
    margin: 5% 0;
  }

  .background {
    height: 230px;
  }

  .footer-top img {
    width: 85%;
  }
}

#mailIcon {
  color: white !important;
}
