.addDancerScroll::-webkit-scrollbar {
  display: none;
}
.addDancerScroll:focus-visible,.addDancerScrollBox:focus-visible{
  outline:none !important;
}
div::-webkit-scrollbar {
  display: none;
}

.boxScroll {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  height: 90vh;
}

.boxScroll::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
#addDancerinput,
#editDancerinput {
  padding: 0px 30px 0px 20px !important;
}
.addDancermodelLabel label {
  margin-left: 5px !important;
}

.formikSelect {
  /* font-size: 14px; */
  -webkit-appearance: none;
  background-image: url(../../Assets/selectArrow.png);
  background-repeat: no-repeat;
  background-size: 5%;
  background-position: top 50% right 4%;
  width: 248px;
}

.formikInput::placeholder {
  color: #eaeaea !important;
  font-size: 14px;
}

.formikInput {
  width: 248px;
}
.input-text-accomodation-des::placeholder {
  padding-left: 7px;
}
.input-box-org-des-error::placeholder {
  padding-left: 7px;
}
.add-dancer {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-div,
.edit-div {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: auto;
  border-radius: 4px !important;
}
.acc-div {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  max-height: 80vh;
  width: 50vw;
  padding:20px;
  border-radius: 4px !important;
}
.acc-outer-div:focus-visible{
outline:none !important;
}
.close-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 25px 0px 28px;
  margin-top: 33px;
}

.close-icon {
  cursor: pointer;
  width: 14px;
  color: #a4a4a4;
}

.color-subtitle {
  font-size: 24px;
  font-weight: 400;
}
.acc-desc{
  max-height:50vh;
  overflow:scroll;
}
.MuiBackdrop-root-MuiModal-backdrop {
  position: absolute !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
  height: 104vh !important;
}

@media ((min-width: 0px) and (max-width:400px)) {
  .edit-div {
    width: auto;
  }
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
  .main-div,
  .edit-div {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    overflow: scroll;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .css-1gpd80c {
    width: 90% !important;
    max-height: 90% !important;
    overflow: scroll;
  }
  .acc-div{
    width:80vw;
    overflow: scroll;
  }
  .acc-desc{
    max-height: auto;
    overflow:auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .acc-div{
    width:80vw;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1930px) {
  .login-dancer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;
    width: 37%;
    height: auto;
    left: 50%;
    margin: auto;
    top: calc(50%);
    background: #ffffff;
    box-shadow: 0px 10px 22px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1900px) {
  .main-div,
  .edit-div {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    height: auto;
    width: 500px;
    border-radius: 4px !important;
  }

  .boxScroll {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    height: 90vh;
  }
}

@media only screen and (min-width: 1900px) {
  .main-div,
  .edit-div {
    display: flex !important;
    flex-direction: column !important;
    background-color: #ffffff !important;
    height: auto !important;
    width: 421px !important;
    margin-bottom: 0rem !important;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1330px) {
  .MuiBackdrop-root-MuiModal-backdrop {
    position: absolute !important;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    z-index: -1;
    height: 140vh !important;
  }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  -modal .css-1gpd80c {
    width: 90% !important;
  }
}
