.checkbox {
  font-size: 8px;
  font-weight: 400;
  padding: 10px;
  background: #eaeaea;
  border-radius: 4px;
}

.checkedbox {
  font-size: 8px;
  font-weight: 400;
  padding: 10px;
  background: #797979;
  border-radius: 4px;
  color: white;
}
.stripePage {
  width: 40vw;
}
.creditCard {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  margin-top: 40px;

  color: #323232;
}
.eventdetailss {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 5px;
}

.leftDiv label {
  margin-left: 0 !important;
}

.familyServiceDiv {
  opacity: 0.3;
}

.btn {
  font-size: 10px !important;
  border: none !important;
  padding: 10px !important;
  border-radius: 4px !important;
  background-color: #EAEAEA !important;
  color:#323232 !important;
}

.btnActive {
  color: white !important;
  background-color: #323232 !important;
  font-size: 10px !important;
  border: none !important;
  padding: 10px !important;
  border-radius: 4px;
}

.check-reg-inp {
  font-size: "8px !important";
  font-weight: "400 !important";
  margin-top: "10px !important";
  padding: "10px !important";
  background: "#EAEAEA !important";
  border-radius: "4px !important";
}

[type="checkbox"] {
  vertical-align: middle;
  accent-color: #ffffff;
  margin-right: 10px;
}

.reg-in-det {
  font-size: 12px;
  font-weight: 500;
  color:#424242;
}

.leftDiv {
  height: 92vh;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.leftDiv::-webkit-scrollbar {
  display: none;
}

.purchase-btn {
  background: #68ad3f;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 11px;
  border: none;
  color: white;
  width: 100%;
}
.purchase-btn:hover {
  background-color: #fbb040;
}

.purchase-btn-disabled {
  background: #68ad3f;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 11px;
  border: none;
  color: white;
  opacity: 0.3;
  width: 100%;
}

.reg-total {
  font-weight: 400;
  font-size: 30px;
  color: #68ad3f;
}

.reg-name-font {
  font-size: 18px;
  font-weight: 400;
  color:black;
}

.reg-name-font-opacity {
  font-size: 18px;
  font-weight: 400;
  opacity: 0.3;
}

.reg-amt {
  font-weight: 400;
  font-size: 16px;
  color: #ffff;
  background: #fbb040;
  padding: 1px 6px;
  text-align: center;
  border-radius: 4px;
}

.parentRegister .row > * {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.registerevents {
  width: 100%;
  border-bottom: 1.89372px solid rgba(140, 174, 120, 0.12);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  margin-bottom: 40px;
}

.registerevents1 {
  width: 100%;
}

.webeventslist {
  display: flex;
  align-items: center;
  width: 90% !important;
}

.eventimg {
  height: 75px;
  width: 75px;
}

.eventdetails {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 5px;
}


.eventmobile {
  display: none;
}

.eventmobilediv {
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 20px;
}

.regBtn button {
  background-color: #eaeaea !important;
  color: #323232 !important;
  border: #eaeaea !important;
}

.buttonGroup label {
  margin-bottom: 10px;
  border-radius: 7px;
  margin-left: -3px;
}

label.btn {
  background-color: #eaeaea !important;
  color: #323232 !important;
  border: #eaeaea !important;
  text-align: left;
}

.labelButton:active {
  background-color: #323232 !important;
  color: #eaeaea !important;
  border: #eaeaea !important;
}

label.activeBtn {
  background-color: #797979 !important;
  color: #ffffff !important;
  border: #eaeaea !important;
}

input:checked .action {
  color: #fff !important;
}

.btn-group > .btn:active {
  color: #fff !important;
}

.input:checked {
  background-color: #f75a1b !important;
}

.dancerFeeBtn {
  background: #fbb040;
  border: none;
  border-radius: 4px;
  padding: 1px 6px;
  color: white;
}

.dancerNameHeading {
  font-size: 1.1em;
  text-transform: capitalize;
  font-weight: 500;
  color:black
}
form button {
  background: #68ad3f;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 11px;
  border: none;
  color: white;
  margin-top: 1em;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .leftDiv {
    height: auto;
  }
  .stripePage {
    width: auto !important;
  }
}
@media screen and (max-width: 1200px) {
  .chosegradee {
    border: 2px solid rgb(234, 234, 234) !important;
    padding: 5px;
    width: auto;
    display: flex;
    flex-wrap: wrap;
  }
}

.custom-checkbox {
  cursor: pointer;
}


.custom-checkbox label {
  top: 0;
  left: 0;
  height: 20px;
}

.custom-checkbox label::after {
  content: "";
  opacity: 0;
}

.custom-checkbox input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

.reg-left-container {
  height: 75vh;
  overflow-y: scroll;
}
.right-calc-div{
   background: #fafafa;
    height: 96vh;
    overflow-y: scroll;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .mobile_eventName {
    max-width: 100%;
  }
  .reg-left-container {
    height: auto;
  }
  .right-calc-div{
     height: auto;
 }
}
