.login-ft-btn {
  font-size: 16px;
 font-weight: 500;
 padding: 0px 11px;
 background-color: transparent;
 border-radius: 4px;
 color: #68AD3F;
 border: 2px solid #68AD3F !important;
 width: 80px;
 height: 42px;
}
.login-ft-btn::hover{
  border: 2px solid #68AD3F !important;
}
.signup-btn {
  font-size: 16px;
  font-weight: 500;
  padding: 9px 11px;
  background-color: #68AD3F;
  border-radius: 4px;
  color: white;
  border: none;
  width: 80px;
  height: 42px;
}
.create-event-col-1{
  color: var(--neutral-text, #424242);
  font-size: 14px;
  display:flex;
justify-content:end;
}

.create-event-col-2{
  color: #FBB040;
font-size: 14px;
font-weight: 500;
display:flex;
justify-content:end;
cursor:pointer;
}
.create-event-col-3{
  color:#68AD3F;
  font-size: 14px;
font-weight: 500;
cursor:pointer;
}

