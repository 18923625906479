
.no-pagination-margin{
  margin-bottom:100px;
}
.back-color {
  background-color: #fafafa;
  height: 100%;
  width: 100%;
}

.open-events-parent {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 50px;
  border-bottom: 1px solid #eaeaea;
}

.dashboard-name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  color: #424242;
  margin-bottom: 0 !important;
  margin-top: 15px !important;
}

.event-btn-pd {
  display: flex;
  flex-grow: 1;
  justify-content: end;
}
.enteredEvents h5 {
  font-family: "Rubik";
  font-style: Medium;
  font-size: 24px;
  line-height: 120%;
  vertical-align: Top;
  margin: 23px 0px 9px 0;
}
#parentDashboardtab {
  color: "#424242 !important";
}

.user-name {
  height: 100px;
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: 0px 15px 42px rgb(0 0 0 / 5%);
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 20px;
}

.MuiPaginationItem-root:hover {
  background-color: #fbb040 !important;
  color: white;
}

/* <---------------------------- media query for mpbile devices -------------------------------------> */
@media only screen and (min-width: 0px) and (max-width: 560px) {
  .open-events-parent {
    margin-bottom: 20px;
    height: 100px;
  }
  .pd-tabDiv {
    width: 100%;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .open-events-parent {
    margin-bottom: 20px;
    height: 100px;
  }
  .pd-tabDiv {
    width: 100%;
  }
  .EventsList-pd {
    margin: 0px !important;
  }
  .EventsList-pd .css-1pgd7le-MuiButtonBase-root-MuiTab-root {
    min-width: auto !important;
  }
  .user-name {
    padding: 20px 20px 20px 0;
  }
  .dashboard-name {
    font-size: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pd-tabDiv {
    width: 80%;
  }
  .eventListw .MuiTabs-flexContainer {
    margin-top: 41px;
  }
}
@media only screen and (min-width: 1024px) {
  .pd-tablist .css-1pgd7le-MuiButtonBase-root-MuiTab-root {
    padding: 10px 50px !important;
  }
  .pd-tabDiv {
    display: flex;
    width: 75%;
  }
}
@media screen and (min-width:1800px) {
  .helpImageDiv {
    position: fixed;
    bottom: 1vw !important;
    right: 1.5vw !important;
    /* height: 54px; */
}
}

@media screen and (max-width:560px) {
  .dropdown-toggle::after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAYCAYAAACMcW/9AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACnSURBVHgBzdfJDcAgDARAd04JlOASKYEEEn8SQjh8rWSJB2JHiA8AV8I56Z4I9ml6yiI/BsEuCG9PgVd1Bh9Y/LCkHlQbix1HhYbOBi0s/hji6EZJ7HS3BXa5UxO73aWBZeuQxLKfLYEVuwDOg8WfFEeBOJKjSA25U6iOXCk2Q84AzJGjWBfIHaw6kjKDNUNSRrDmSEoP6wZJaWHdISnly0Df3ACMOQATckHecQwCXQAAAABJRU5ErkJggg==);
    color: #797979 !important;
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
}

