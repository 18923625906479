.dancer-name{
  font-size: 16px;
  color: #424242;
  font-weight: 400;
  overflow-wrap: anywhere;
  text-align: justify;
}
.dancer-bday{
  font-size: 12px;
  color: rgb(104, 173, 63);
  font-weight: 500;
}
.dancer-school{
  font-size: 12px;
  color: #424242;
    font-weight: 500;
    overflow-wrap: anywhere;
    padding: 0px 57px 0px 0px;
    text-align: justify;
}
.deleteIcon-dancer{
  font-size: 20px;
  color: rgb(121, 121, 121);
  margin-top: 7px;
  margin-right: 7px;
}
.editIcon-dancer{
  font-size: 18px;
  color: rgb(121, 121, 121);
  margin-top: 8px;
  margin-right: 7px;
}

.updateChangeDisabled {
  opacity: 0.3;
  background-color: #68ad3f !important;
  color: white !important;
  pointer-events: none;
}
.account-flow .MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #68AD3F !important;
}
.css-sex162-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  background-color: #68ad3f !important;
  color: #ffff !important;
}
.stripePagemain {
  width: 40vw;
}
.eventNameDancer{
  margin: 6px 0.25rem 0px 0.25rem !important;
}

.updateChange:hover {
  background-color: rgb(251, 176, 64);
}
.updateChange {
  cursor: pointer;
}
.account-flow .MuiButtonBase-root {
  font-size: 16px !important;
}
.hrpasword{
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  color: #EAEAEA;
}
.add_dance_btn{
  font-size: 17px !important;
}
.account-tab {
  border-bottom: 2px solid rgba(140, 174, 120, 0.12);
}
.account-tab .MuiTab-root {
  min-width: auto !important;
  padding: 12px 0 !important;
  color: #424242;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.tabs .css-13xfq8m-MuiTabPanel-root {
  padding: 24px 0;
}
.disabled-email {
  color: #c9c9c9 !important;
  background-color: transparent !important;
}
.account-flow h3 {
  font-weight: 400;
  margin: 30px 0 0 15px;
}
.account-flow input {
  width: 258px !important;
}
.account-flow select {
  width: 258px !important;
}
.dancers-profile {
  width: 310px;
  box-shadow: 0px 15px 28px rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin-bottom: 10px;
}
.accountInput::placeholder {
  color: #eaeaea;
  font-size: 14px;
  font-weight: 400;
}
@media (min-width: 481px) {
  .account-tab {
    padding: 0 10%;
  }
  .tabs {
    padding: 0 10%;
  }
  .account-flow h3 {
    font-size: 20px;
    margin: 30px 0 0 11%;
  }
}
@media (min-width: 768px) {
  .account-tab {
    padding: 0 20%;
  }
  .tabs {
    padding: 0 20%;
  }
  .account-flow h3 {
    margin: 30px 0 0 22%;
  }
}
@media (min-width: 1024px) {
  .account-tab {
    padding: 0 30%;
  }
  .tabs {
    padding: 0 30%;
  }
  .account-flow h3 {
    font-size: 24px;
    margin: 30px 0 0 10%;
  }
}
@media ((min-width: 0px) and (max-width:700px)) {
  .css-byiuq4-MuiButtonBase-root-MuiTab-root {
    min-width: auto !important;
  }

  .account-flow {
    padding: 0 10px;
  }
}
