.cursor-pointer{
cursor:pointer;
}
.error-class{
  color:red;
  font-size: 12px;
}
.tabulate-select option:disabled {
  color:rgb(168, 159, 159); 
  background-color: #f5f5f5; 
}

.competitor-count {
  padding: 0px 6px;
  background: #fbb040;
  border-radius: 4px;
  font-size: 16px;
  color: #ffffff;
}
.search-label {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  margin-left: 5px;
  margin-bottom: 2px;
  width: 100%;
}
.searchFeisComp,
.searchFeisComps {
  width: 248px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #797979;
  border-radius: 4px;
  padding: 10px;
}
.searchFeisComps {
  width: 340px;
}
.searchFeisCompsSelect {
  width: 340px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #797979;
  border-radius: 4px;
  outline: none;
}
input.searchFeisComp {
  outline: none;
}
.org-body-white {
  padding: 0 7%;
}
.ql-editor {
  background: white !important;
  padding: 20px !important;
  height: 100px !important;
}
.ql-snow.ql-toolbar button {
  border-radius: 0;
  box-shadow: none;
  margin-top: 0;
}
.feis-manage {
  font-size: 14px;
  color: #424242;
}
.email-msg {
  border: 1px solid #797979;
  background: #ffffff;
  border-radius: 4px;
}
.text-area {
  border: none;
  outline: none;
  border-radius: 4px;
  resize: none;
  min-height: 150px;
  width: 100%;
  padding: 10px 10px 0 10px;
}
.text-area::-webkit-scrollbar {
  display: none;
}
.check-in-select {
  border-radius: 4px;
  border: 1px solid #797979;
  background: #fff;
  width: 311px;
  -webkit-appearance: none;
  background-image: url('../../../Assets/selectArrow.png');
  background-size: 15px 8px !important;
  background-repeat: no-repeat;
  background-size: 4%;
  background-position: top 50% right 6%;
}
.check-in-input{
  border-radius: 4px;
  border: 1px solid #797979;
  background: #fff;
  width: 311px; 
  -webkit-appearance: none;
padding:9px
}
.edit-register-org {
  background-image: linear-gradient(
    to right,
    #fff 0%,
    #fff 50%,
    #fff 50%,
    #fafafa 72%
  );
  padding: 0 7%;
}
.wrap-select {
  width: calc(33.33% - 12px); 
  height: 60px;
  border-radius: 4px;
}

.wrap-select-with-round {
  width: calc(25% - 16px);
  height: 60px;
  border-radius: 4px;
}
.tabulate-select-div {
  display: flex;
  flex-wrap: wrap;
  column-gap: 18px;
}

.tabulate-select {
  border-radius: 4px;
  border: 1px solid #797979;
  background: #fff;
  width: 100%;
  -webkit-appearance: none;
  background-image: url('../../../Assets//selectArrow.png');
  background-size: 15px 8px !important;
  background-repeat: no-repeat;
  background-size: 4%;
  background-position: top 50% right 6%;
}
.tabulate-input{
  border-radius: 4px;
  border: 1px solid #797979;
  background: #fff;
  width: 100%;
  padding:9px;
  -webkit-appearance: none;
}
.input-code {
  height: 40px;
  width: 100%;
  border: 1px solid #797979;
  border-radius: 4px;
}
.input-desc {
  height: 80px;
  width: 100%;
  border: 1px solid #797979;
  border-radius: 4px;
}
.input-code input {
  width: 100%;
  height: 100%;
  padding: 5px;
  border-radius: 4px;
  border: none;
  resize: none; 
  outline: none; 
}

.table-text-area {
  border: none;
  outline: none;
  border-radius: 4px;
  resize: none;
  min-height: 100%;
  width: 100%;
  padding: 5px;
}
.input-code input {
  text-align: center;
}
.transaction-card {
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.05);
  height: 80px;
}
.custom-select:focus {
  border-color: #797979 !important;
}

.visibleErr{
  visibility:visible;
  color:red;
  font-size: 12px;
}
.invisible{
  visibility:hidden;
}
@media only screen and (min-width: 0px) and (max-width: 480px) {
  .searchFeisComp {
    width: 100%;
  }
  .searchFeisCompsSelect {
    width: 100%;
  }
  .searchFeisComp::placeholder {
    font-size: 10px;
  }
  .org-body-white {
    padding: 0 5%;
  }
  .edit-register-org {
    background-image: none;
    padding: 0;
  }
  .editOrg-leftDiv,
  .editOrg-rightDiv {
    padding: 0 5% !important;
  }
  .tabulate-select-div {
    flex-direction: column;
  }
  .tabulate-select {
    width: 311px;
  }
  .wrap-select {
    margin-top: 5px;
  }
  .wrap-select-with-round {
width:auto;
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .searchFeisComp {
    width: 340px;
  }
  .org-body-white {
    padding: 0 5%;
  }
  .edit-register-org {
    background-image: none;
    padding: 0;
  }
  .editOrg-leftDiv,
  .editOrg-rightDiv {
    padding: 0 5% !important;
  }
  .tabulate-select-div {
    display: block;
  }
  .tabulate-select {
    width: 311px;
  }
  .wrap-select {
    margin-top: 1rem;
  }
  .wrap-select-with-round {
    width:auto;
      }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .check-in-select {
    width: 250px;
  }
  .check-in-input {
    width: 250px;
  }
  .org-body-white {
    padding: 0 7%;
  }
  .edit-register-org {
    padding: 0 7%;
  }
}
