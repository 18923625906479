.feis-logo{
  width:136px;
  cursor: pointer;
}
.navbar-expand-sm .offcanvas {
  justify-content: center;
}
.green{
  color:#68AD3F !important;
}
.grey-32{
  color:#323232 !important;
}
.searchDiv {
  margin-right: 7px;
  margin-top: 12px;
}
.search,
.search:focus {
  border-width: 0px;
  border-radius: 5px;
  outline: 0;
  -webkit-appearance: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
::-webkit-input-placeholder {
  color: #797979;
}

:-ms-input-placeholder {
  color: #797979;
}

::placeholder {
  color: #797979;
}
.web-btn {
  min-width: 134px !important;
}

.MuiTabs-flexContainer {
  justify-content: space-between;
}

.gridImage {
  padding: 0 !important;
}

.gridImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.home-page-tab .MuiTabs-indicator {
  height: 3px;
}
#mui-p-41355-T-1 .MuiTabs-indicator {
  background-color: #000000 !important;
}
#mui-p-43674-T-2 {
}
.gridImage1 {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.MuiTabs-root {
  flex-grow: 1;
}

.MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: initial;
  border: 2px solid #68ad3f;
}
.MuiButtonBase-root-MuiButton-root {
  line-height: normal !important;
  padding: 10px !important;
}
.advantages-card p {
  padding-top: 10px;
}

.font-20 {
  padding: 50px 0px 50px 0px;
  font-size: 20px;
  color: #68ad3f;
  text-align: center;
}

.border-btm {
  border-bottom: 1px solid rgb(241, 241, 241);
  z-index: -1;
  position: absolute;
  width: 100%;
  left: 0px;
}

.bg-1 {
  background-color: #f4ffee;
  height: 300px;
  width: 100%;
  z-index: -1;
  position: absolute;
  left: 0px;
  transform: skewY(-2deg);
  margin: 40px 0px 40px 0px;
}

.bg-2 {
  background-color: #fffaf2;
  height: 300px;
  width: 100%;
  z-index: -2;
  position: absolute;
  left: 0px;
  transform: skewY(2deg);
  margin: 40px 0px 40px 0px;
}

.dashboard-Org {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #68ad3f;
  height: 289px;
  margin: 80px 0px 80px 0px;
}

.registration {
  height: 393px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 12px !important;
}

.searchbutton {
  border: none;
  background: white;
  margin-left: -40px !important;
  justify-content: center;
}

.desktop-heading {
  display: block;
  font-weight: 700;
  color: #88d35b;
}

.heading-color {
  color: #fbb040;
}

.mobile-heading {
  display: none;
}

.border-line {
  border-bottom: 1px solid rgb(241, 241, 241);
  margin-top: 20px;
  z-index: -1;
  position: absolute;
  width: 100%;
  left: 0px;
}

.border-line-2 {
  border-bottom: 1px solid rgb(241, 241, 241);
  margin-top: 5px;
  z-index: -1;
  position: absolute;
  width: 100%;
  left: 0px;
}

.text-center {
  text-align: center;
}

.open-events {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.advantages {
  text-align: center;
  line-height: 31.5px;
  padding: 50px 0px 50px 0px;
}

.advantages-list {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding: 62px;
}

.adv-title {
  color: #424242;
  font-size: 24px;
  line-height: 28.44px;
  font-weight: 500;
}

.search {
  width: "219px";
  padding: 10px 10.51px 10px 10px;
  margin-right: -4px;
  outline: none;
}

.getPaidIcon {
  width: 60px;
  height: 60px;
  background: #ececec;
  border-radius: 50px;
}

.WhyFeis h2 {
  font-family: "Rubik";
  font-weight: 500;
  font-size: 24px;
  color: black;
  margin: 120px 0 40px 0;
}

.WhyFeis {
}

.adv-desc {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #424242;
  opacity: 0.7;
}

.experience {
  display: flex;
  align-items: center;
  padding: 50px 0px 50px 0px;
}

/* .kids-img {
  height: 100%;
  width: 500px;
} */

.exp-details {
  margin-top: 20px;
  font-size: 21px;
  line-height: 32px;
  text-align: left;
  color: rgba(0, 0, 0, 0.702469);
}

.dashboard-organizer img {
  width: 180.95px;
  height: 62px;
}

.dashboard-organizer {
  height: 318px;
  position: relative;
  font-family: "Rubik";
  color: #68ad3f;
  column-gap: 10px;
}

.dashboard-organizer h3 {
  font-weight: 700;
  font-size: 52px !important;
}

.bg-3 {
  background-color: #f4ffee;
  width: 100%;
  z-index: -2;
  position: absolute;
  left: 0px;
  transform: skewY(2deg);
  height: 318px;
}

.background-slant {
  height: 300px;
  width: 100%;
  z-index: -1;
  position: absolute;
  left: 0px;
  background-color: #f4ffee;
  transform: skewY(1deg);
  margin: 40px 0px 40px 0px;
}

.event-links {
  height: 819px;
  display: flex;
  align-items: center;
}

.event-links-title {
  text-transform: uppercase;
}

.event-section-mobile {
  display: none;
}

.event-section {
  display: block;
  z-index: -1;
  position: absolute;
  right: 0px;
  width:40%;
}

.events-image {
  width: 100%;
}

.links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 60px 0px 40px 0px;
}

.EventsList {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  /* margin : 0px 20px; */
}

.EventsList1 {
  display: flex;
  column-gap: 30px;
}

.links-card {
  display: flex;
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  height: 80px;
  width: 273px;
  margin: 20px;
  padding: 10px;
}

.link-name {
  margin: 0px 0px 0px 10px;
}

.orgaizerImg {
  display: flex;
  width: 77vw !important;
  height: auto !important;

  top: 0px;
}

.orgainzer-Content {
  width: 796px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-top: 50px;

  text-align: center;

  color: #797979;
}

.orgainzer-Content span,
a {
  color: #68ad3f !important;
  text-decoration: none !important;
}

@media only screen and (min-width: 0px) and (max-width: 320px) {
  .EventsList button {
    font-size: 12px;
    padding: 2px;
    width: auto;
  }
}

/*  <---------------------------- media query for mpbile devices ------------------------------------->  */
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .feis-logo{
    width:auto;
  }
  .EventsList-pd .MuiTabs-flexContainer .MuiTabs-flexContainer {
    column-gap: 20px;
  }
  .dashboard-organizer {
    height: 210px;
  }
  .mob-btn {
    margin-top: -3rem !important;
    margin-top: -3rem !important;
  }
  .event-links {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .cardSection .MuiTabPanel-root{
    padding: 8px 24px;;
  }
  .css-ke65ae-MuiTabs-indicator {
    position: absolute;
    height: 2px;
    bottom: 8px !important;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #68ad3f;
  }
  .orgainzer-Content {
    width: 796px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-top: 5px;
    text-align: center;
    color: #797979;
  }
  .home-page-tab .MuiTabs-flexContainer .MuiButtonBase-root {
    flex-grow: 1;
    display: "flex";
    margin-bottom: -9px;
    min-width: 10px !important;
    padding: 12px 0px !;
    font-size: 12px;
    min-height: 56px !important;
  }
  .home-page-tab .MuiTabs-flexContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    padding-inline: 20px;
    display: flex;
    padding-inline: 20px;
  }
  .desktop-content {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 23px !important;
    line-height: 0px;
    text-align: center;
    text-transform: uppercase;
    color: #424242;
  }

  .WhyFeis h2 {
    margin: 40px 0 40px 0;
  }

  .dashboard-organizer h3 {
    font-size: 17px !important;
  }

  .dashboard-organizer img {
    width: 81.95px;
    height: 62px;
  }

  .font-20 {
    padding: 20px 0px 50px 0px;
  }

  .advantages-list {
    padding: 0px;
  }

  .search,
  .search:focus {
    width: 76.3vw;
    height: 35px;

    border-width: 0px;
    outline: 0;
    -webkit-appearance: none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }

  .searchbutton {
    margin-top: 15px;
  }

  .EventsList {
    margin: 0px 20px;
  }

  .EventsList1 {
    flex-wrap: wrap;
  }

  .login-btn {
    font-size: 10px !important;
    width: 100% !important;
    height: auto !important;
  }

  #regBtn {
    cursor: pointer;
    font-size: 0.875rem !important;
    color: white !important;
  }

  .EventsList button {
    font-size: 12px;
    padding: 2px;
    width: auto;
  }


  .border-btm {
    display: none;
  }

  .mobile-heading {
    font-weight: 700;
    display: block;
    color: #68ad3f;
  }

  .desktop-heading {
    display: none;
  }

  .registration {
    height: 300px;
  }

  .open-events {
    height: 100%;
    margin-bottom: 20px;
  }

  .bg-1 {
    height: 200px;
  }

  .bg-2 {
    height: 200px;
  }

  .bg-3 {
    height: 200px;
  }

  .bg .register-events {
    padding: 20px 0px 20px 0px;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
  }

  .event-name {
    font-size: 14px;
  }
  .reg-datee {
    font-size: 11px;
  }

  .border-line {
    display: none;
  }

  .web-btn {
    display: none !important;
  }

  .advantages {
    padding: 30px 20px 30px 20px;
  }

  .advantages-list {
    display: flex;
    flex-direction: column;
  }

  .advantages-card {
    padding: 10px 20px 10px 20px;
  }

  .exp-section {
    text-align: center;
    padding: 10px 0px 20px 0px;
  }

  .exp-details {
    text-align: center;
    padding: 20px 0px 0px 0px;
  }

  .experience {
    flex-direction: column-reverse;
    padding: 60px 20px 60px 20px;
  }

  /* .kids-img {
    width: 100%;
    padding: 20px 0px 0px 0px;
  } */

  .event-section-mobile {
    display: block;
  }

  .background-slant {
    height: 250px;
  }

  .organizer {
    height: 200px;
  }

  .event-section {
    display: none;
  }

  .create-event {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .event-links {
    height: 100%;
    flex-direction: column;
    padding: 80px 0px 30px 0px;
  }

  .events-image-mobile {
    width: 100%;
    padding: 20px 0px 20px 0px;
  }

  .why-feis {
    height: 100%;
  }

  .border-line-2 {
    display: none;
  }

  .mobile-btn {
    background-color: #68ad3f;
    color: white;
  }

  .links-card {
    justify-content: flex-start;
    padding: 15px;
  }

  .link-name {
    margin-left: 10px;
  }

  .link {
    margin-top: 4px;
  }
}

.MuiButtonBase-root-MuiButton-root {
  flex: none;
}

.organizer-content {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: #68ad3f;
  flex: none;
  order: 1;
  flex-grow: 0;
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
  .heading-color {
    font-size: 30px !important;
  }

  .mobile-heading {
    font-size: 30px !important;
  }

  .desktop-content {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 800px) {
  .header.navbar-expand-sm .offcanvas {
    position: relative;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
    display: none !important;
  }
}
/* / <---------------------------- media query for ipad devices -------------------------------------> / */
@media only screen and (min-width: 760px) and (max-width: 980px) {
  /* .kids-img {
    width: 300px;
  } */
  .OrgImage {
    padding: 2rem;
  }

  .advantages-list {
    padding: 0px;
    margin-top: 80px;
  }
  .dashboard-organizer h3 {
    font-weight: 700;
    font-size: 33px !important;
  }
  .dashboard-organizer img {
    width: 120px;
  }
  .devincornell {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .event-section {
    width: 50%;
  }

  .event-links {
    height: 556px;
  }

  .event-links h2 {
    font-size: 30px !important;
  }

  .dashboard-organizer {
    align-items: center;
  }
  .desktop-content {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 40px !important;
    line-height: 0px;
    text-align: center;
    text-transform: uppercase;
    color: #424242;
  }

  #EventsList2 button {
    font-size: 15px !important;
  }

  .gridImagerow {
    margin-top: 50px;
  }

  .EventsList {
    margin-top: -76px;
  }
  .home-page-tab .MuiTabs-flexContainer .MuiButtonBase-root {
    min-height: 55px !important;
    flex-grow: 1;
    display: flex;
    margin-bottom: -8px;
    min-width: 10px !important;
    padding: 12px 0px !;
    font-size: 12px;
  }
}

/*  <---------------------------- media query for ipad pro devices ------------------------------------->  */
@media only screen and (min-width: 981px) and (max-width: 1210px) {
  /* .kids-img {
    width: 400px;
  } */
  .OrgImage {
    padding: 2rem;
    margin-top: "110px";
  }
  .dashboard-organizer {
    align-items: center;
  }
  .event-links {
    margin-left: 0vw !important;
  }

  [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
  }
  .desktop-content {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 40px !important;
    line-height: 0px;
    text-align: center;
    text-transform: uppercase;
    color: #424242;
  }
}
@media screen and (min-width: 1200px) {
  .eventListw {
    width: 65vw;
  }
  .MuiTabs-indicator {
    position: absolute;
    height: 2px;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #68ad3f;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1900px) {
  .OrgImage {
    padding: 3rem;
    margin-top: "110px";
  }
  .EventsList2 {
    padding: 0px 0px 4rem 0px !important;
  }

  .orgainzer-Content {
    width: auto;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-top: -17px;
    text-align: center;
    color: #797979;
    margin-bottom: 36px;
  }
  .desktop-content {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 40px !important;
    text-align: center;
    text-transform: uppercase;
    color: #424242;
  }
  .dashboard-organizer {
    align-items: center;
    margin-bottom: 6rem;
  }
}

@media only screen and (min-width: 1900px) and (max-width: 1930px) {
  .devincornell {
    padding: 50px 0px 0px 0px !important;
  }

  .dashboard-organizer {
    align-items: center;
    margin-bottom: 6rem;
  }
  .EventsList2 {
    padding: 0px 0px 4rem 0px !important;
  }
  .OrgImage {
    padding: 4.5rem;
  }
  .desktop-content {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 40px !important;
    text-align: center;
    text-transform: uppercase;
    color: #424242;
  }

  .organizer {
    margin-bottom: 6rem;
  }
  .title-signup {
    height: 65px;
    width: 165px;
  }
  .title-signup :hover {
    border: 2px solid #68ad3f;
  }
  .create-event-btn {
    padding: 13px !important;
    border: 3px solid #68ad3f !important;
  }
  .MuiTypography-root {
    margin: 0 !important;
    font-family: Rubik !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    margin-bottom: 0.35em !important;
    margin-bottom: 25px !important;
    max-width: 100% !important;
  }

  .orgainzer-Content {
    width: 100% !important;
    font-family: "Rubik" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 150% !important;
    margin-top: 50px !important;
    text-align: center !important;
    color: #797979 !important;
  }

  .WhyFeis h2 {
    font-family: "Rubik" !important;
    font-weight: 500 !important;
    color: black !important;
  }

  .event-section {
    display: flex;
    z-index: -1;
    position: absolute;
    right: 0px;
  }

  .event-section {
    position: relative;
    display: none;
  }

  .event-section-mobile {
    display: contents;
  }

  .events-image-mobile {
    height: 67%;
    position: absolute;
    float: right;
    right: 0%;
  }

  .event-links {
    height: 563px;
    display: flex;
    align-items: center;
  }

  .adv-desc {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #424242;
    opacity: 0.7;
  }

  .advantages-list {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
  }

  .css-112coge {
    max-width: 100% !important;
  }

  .adv-title {
    color: #424242 !important;
  }
  .search, .search:focus {
    border-width: 0px;
    border-radius: 5px;
    outline: 0;
    -webkit-appearance: none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }

  .MuiTabs-flexContainer {
    justify-content: space-between;
  }

  .imageGridContainer {
    margin-bottom: 100px;
  }

  .endGrid {
    padding: 0 !important;
  }

  .gridImage {
    padding: 0 !important;
  }

  .gridImage {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .gridImage1 {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .MuiTabs-root {
    flex-grow: 1;
  }

  .MuiButtonBase-root-MuiButton-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: initial;
    border: 2px solid #68ad3f;
  }
  .MuiButtonBase-root-MuiButton-root {
    line-height: normal !important;
    padding: 10px !important;
  }
  .advantages-card p {
    padding-top: 10px;
  }

  .font-20 {
    padding: 50px 0px 50px 0px;
    font-size: 20px;
    color: #68ad3f;
    text-align: center;
  }

  .border-btm {
    border-bottom: 1px solid rgb(241, 241, 241);
    z-index: -1;
    position: absolute;
    width: 100%;
    left: 0px;
  }

  .bg-1 {
    background-color: #f4ffee;
    height: 300px;
    width: 100%;
    z-index: -1;
    position: absolute;
    left: 0px;
    transform: skewY(-2deg);
    margin: 40px 0px 40px 0px;
  }

  .bg-2 {
    background-color: #fffaf2;
    height: 300px;
    width: 100%;
    z-index: -2;
    position: absolute;
    left: 0px;
    transform: skewY(2deg);
    margin: 40px 0px 40px 0px;
  }

  .dashboard-Org {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #68ad3f;
    height: 289px;
    margin: 80px 0px 80px 0px;
  }

  .registration {
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .searchbutton {
    border: none;
    background: white;
    margin-left: -40px !important;
    justify-content: center;
  }

  .desktop-content {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
  }

  .desktop-heading {
    display: block;
    font-weight: 700;
    color: #88d35b;
    font-size: 60px !important;
  }

  .heading-color {
    color: #fbb040;
  }

  .mobile-heading {
    display: none;
  }

  .border-line {
    border-bottom: 1px solid rgb(241, 241, 241);
    margin-top: 20px;
    z-index: -1;
    position: absolute;
    width: 100%;
    left: 0px;
  }

  .border-line-2 {
    border-bottom: 1px solid rgb(241, 241, 241);
    margin-top: 5px;
    z-index: -1;
    position: absolute;
    width: 100%;
    left: 0px;
  }

  .text-center {
    text-align: center;
  }

  .open-events {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
  }

  .advantages {
    text-align: center;
    line-height: 31.5px;
    padding: 50px 0px 50px 0px;
  }

  .advantages-list {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding: 62px;
  }

  .adv-title {
    color: #424242;
    font-size: 24px;
    line-height: 28.44px;
    font-weight: 500;
  }

  .search {
    width: "219px";
    padding: 8px 10px;
    margin-right: -4px;
    outline: none;
  }

  .getPaidIcon {
    width: 60px;
    height: 60px;
    background: #ececec;
    border-radius: 50px;
  }

  .WhyFeis h2 {
    font-family: "Rubik";
    font-weight: 500;
    font-size: 24px;
    color: black;
    margin: 120px 0 40px 0;
  }

  .adv-desc {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #424242;
    opacity: 0.7;
  }

  .experience {
    display: flex;
    align-items: center;
    padding: 50px 0px 50px 0px;
  }

  /* .kids-img {
    height: 100%;
    width: 500px;
  } */

  .exp-details {
    margin-top: 20px;
    font-size: 21px;
    line-height: 32px;
    text-align: left;
    color: rgba(0, 0, 0, 0.702469);
  }

  .dashboard-organizer img {
    width: 180.95px;
    height: 62px;
  }

  .dashboard-organizer {
    height: 318px;
    position: relative;
    font-family: "Rubik";
    color: #68ad3f;
    column-gap: 10px;
  }

  .dashboard-organizer h3 {
    font-weight: 700;
    font-size: 52px !important;
  }

  .bg-3 {
    background-color: #f4ffee;
    width: 100%;
    z-index: -2;
    position: absolute;
    left: 0px;
    transform: skewY(2deg);
    height: 318px;
  }

  .background-slant {
    height: 300px;
    width: 100%;
    z-index: -1;
    position: absolute;
    left: 0px;
    background-color: #f4ffee;
    transform: skewY(1deg);
    margin: 40px 0px 40px 0px;
  }

  .event-links {
    height: 819px;
    display: flex;
    align-items: center;
  }

  .event-links-title {
    text-transform: uppercase;
  }

  .event-section-mobile {
    display: none;
  }

  .event-section {
    display: block;
    z-index: -1;
    position: absolute;
    right: 0px;
  }

  .events-image {
    width: 100%;
  }

  .links-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 60px 0px 40px 0px;
  }

  .EventsList {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    margin: 0px 20px;
  }
  .eventListw {
    width: 51vw;
  }

  .EventsList1 {
    display: flex;
    column-gap: 30px;
  }

  .links-card {
    display: flex;
    align-items: center;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    height: 80px;
    width: 273px;
    margin: 20px;
    padding: 10px;
  }

  .link-name {
    margin: 0px 0px 0px 10px;
  }

  .orgaizerImg {
    display: flex;
    width: 77vw !important;
    height: auto !important;
    top: 0px;
  }

  .orgainzer-Content {
    width: 796px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-top: 50px;
    text-align: center;
    color: #797979;
  }

  .orgainzer-Content span,a {
    color: #68ad3f !important;
    text-decoration: none !important;
  }
  /* / <---------------------------- media query for mpbile devices -------------------------------------> / */
  @media only screen and (min-width: 0px) and (max-width: 900px) {
    .dashboard-organizer {
      height: 210px;
    }
    .home-page-tab {
      display: flex;
      flex-grow: 1;
      margin-bottom: 9px;
    }
    .desktop-content {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 25px !important;
      text-align: center;
      text-transform: uppercase;
      color: #424242;
    }

    .WhyFeis h2 {
      margin: 40px 0 40px 0;
    }

    .dashboard-organizer h3 {
      font-size: 17px !important;
      margin-top: 10px;
    }

    .dashboard-organizer img {
      width: 81.95px;
      height: 62px;
    }

    .font-20 {
      padding: 20px 0px 50px 0px;
    }

    .advantages-list {
      padding: 0px;
    }

    .search, .search:focus {
      width: 76.3vw;
      height: 35px;
      border-width: 0px;
      outline: 0;
      -webkit-appearance: none;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }

    .searchbutton {
      margin-top: 15px;
    }

    .EventsList {
      margin: 0px 20px;
    }

    .EventsList1 {
      flex-wrap: wrap;
    }

    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled),
    button:not(:disabled) {
      font-size: 14px;
      width: auto;
      height: auto;
    }

    .login-btn {
      font-size: 10px !important;
      width: 100% !important;
      height: auto !important;
    }

    #regBtn {
      cursor: pointer;
      font-size: 14px !important;
      color: white !important;
    }

    .EventsList button {
      font-size: 16px;
      padding: 2px;
    }

    .border-btm {
      display: none;
    }

    .mobile-heading {
      font-weight: 700;
      display: block;
      color: #68ad3f;
    }

    .desktop-heading {
      display: none;
    }

    .registration {
      height: 300px;
    }

    .open-events {
      height: 100%;
      margin-bottom: 20px;
    }

    .bg-1 {
      height: 200px;
    }

    .bg-2 {
      height: 200px;
    }

    .bg-3 {
      height: 200px;
    }

    .bg .register-events {
      padding: 20px 0px 20px 0px;
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
    }

    .event-name {
      font-size: 12px;
    }

    .border-line {
      display: none;
    }

    .web-btn {
      display: none !important;
    }

    .advantages {
      padding: 30px 20px 30px 20px;
    }

    .advantages-list {
      display: flex;
      flex-direction: column;
    }

    .advantages-card {
      padding: 10px 20px 10px 20px;
    }

    .exp-section {
      text-align: center;
      padding: 10px 0px 20px 0px;
    }

    .exp-details {
      text-align: center;
      padding: 20px 0px 0px 0px;
    }

    .experience {
      flex-direction: column-reverse;
      padding: 60px 20px 60px 20px;
    }

    /* .kids-img {
      width: 100%;
      padding: 20px 0px 0px 0px;
    } */

    .event-section-mobile {
      display: block;
    }

    .background-slant {
      height: 250px;
    }

    .organizer {
      height: 200px;
    }

    .event-section {
      display: none;
    }

    .create-event {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      text-transform: uppercase;
      color: #000000;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .event-links {
      height: 100%;
      flex-direction: column;
      padding: 80px 0px 30px 0px;
    }

    .events-image-mobile {
      width: 100%;
      padding: 20px 0px 20px 0px;
    }

    .why-feis {
      height: 100%;
    }

    .border-line-2 {
      display: none;
    }

    .mobile-btn {
      background-color: #68ad3f;
      color: white;
    }

    .links-card {
      justify-content: flex-start;
      padding: 15px;
    }

    .link-name {
      margin-left: 10px;
    }

    .link {
      margin-top: 4px;
    }
  }

  .MuiButtonBase-root-MuiButton-root {
    flex: none;
  }

  .organizer-content {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: #68ad3f;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  @media only screen and (min-width: 481px) and (max-width: 600px) {
    .heading-color {
      font-size: 30px !important;
    }

    .mobile-heading {
      font-size: 30px !important;
    }

    .desktop-content {
      font-size: 25px !important;
    }
  }

 
  /* / <---------------------------- media query for ipad devices -------------------------------------> / */
  @media only screen and (min-width: 760px) and (max-width: 980px) {
    /* .kids-img {
      width: 300px;
    } */

    .advantages-list {
      padding: 0px;
      margin-top: 80px;
    }

    .event-section {
      width: 50%;
    }

    .event-links {
      height: 556px;
    }

    .event-links h2 {
      font-size: 30px !important;
    }
    .MuiTabs-indicator {
      position: absolute;
      height: 2px;
      bottom: 8px !important;
      width: 100%;
      -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: #68ad3f;
    }

    .dashboard-organizer {
      align-items: center;
    }

    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled),
    button:not(:disabled) {
      cursor: pointer;
      font-size: 14px !important;
    }

    #EventsList2 button {
      font-size: 15px !important;
    }

    .gridImagerow {
      margin-top: 50px;
    }

    .EventsList {
      margin-top: 50px;
    }
  }

  /* / <---------------------------- media query for ipad pro devices -------------------------------------> / */
  @media only screen and (min-width: 981px) and (max-width: 1210px) {
    /* .kids-img {
      width: 400px;
    } */
    .OrgImage {
      padding: 2rem;
    }
    .dashboard-organizer {
      align-items: center;
    }

    .event-links {
      margin-left: 0vw !important;
    }

    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled),
    button:not(:disabled) {
      cursor: pointer;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1800px) {
    .OrgImage {
      padding: 2rem;
    }
    .desktop-content {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 40px !important;
      text-align: center;
      text-transform: uppercase;
      color: #424242;
    }
  }

  @media only screen and (min-width: 1900px) and (max-width: 1930px) {
    .organizer {
      margin-bottom: 6rem;
    }

    .MuiTypography-root {
      margin: 0 !important;
      font-family: Rubik !important;
      font-weight: 400 !important;
      font-size: 1rem !important;
      line-height: 1.5 !important;
      margin-bottom: 0.35em !important;
      margin-bottom: 25px !important;
      max-width: 100% !important;
    }

    .orgainzer-Content {
      width: 90% !important;
      font-family: "Rubik" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 20px !important;
      line-height: 150% !important;
      margin-top: -48px !important;
      margin-bottom: 35px;
      text-align: center !important;
      color: #797979 !important;
    }

    .WhyFeis h2 {
      font-family: "Rubik" !important;
      font-weight: 500 !important;
      color: black !important;
      font-size: 40px;
      font-size: 40px !important;
      padding: 25px;
    }

    .event-section {
      display: flex;
      z-index: -1;
      position: absolute;
      right: 0px;
    }

    .event-section {
      position: relative;
      display: none;
    }

    .event-section-mobile {
      display: contents;
    }

    .events-image-mobile {
      height: 67%;
      position: absolute;
      float: right;
      right: 0%;
    }

    .event-links {
      height: 563px;
      display: flex;
      align-items: center;
    }

    .adv-desc {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      color: #797979;
      opacity: 0.7;
    }

    .advantages-list {
      display: flex;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      padding: 0px;
    }

    .css-112coge {
      max-width: 100% !important;
    }

    .adv-title {
      color: #424242 !important;
      font-size: 30px !important;
      line-height: 28.44px !important;
      font-weight: 500 !important;
    }
    #EventsList2 {
    }

    .EventsList1 {
      display: flex !important;
      column-gap: 30px !important;
    }
  }
}

.EventsList1 {
  display: flex !important;
  column-gap: 30px !important;
}

@media only screen and (min-width: 787px) and (max-width: 2000px) {
  .MuiTabPanel-root {
    padding: 0px 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .create-event Button {
    padding: 12px 32px 12px 32px !important;
    margin-top: 48px !important;
  }
}
@media screen and (min-width: 1800px) {
  .create-event Button {
    padding: 10px 24px 10px 24px !important;
    margin-top: 48px !important;
  }
  .desktop-content {
    font-size: 42px !important;
  }
}
@media screen and (max-width: 480px) {
  .offcanvas-body {
    flex-grow: 1;
    padding: 0px !important;
    overflow-y: auto;
  }
  .header.navbar-expand-sm .offcanvas {
    position: relative !important;
  }
  .navbar-expand-sm {
    padding: 0px 8px !important;
  }
}
@media screen and (max-width: 600px) {
  .title-signup {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 1900px) {
  .desktop-content {
    font-size: 42px !important;
    margin-top: 38px !important;
  }
  .bg-1 {
    background-color: #f4ffee;
    height: 342px;
    width: 100%;
    z-index: -1;
    position: absolute;
    left: 0px;
    transform: skewY(-2deg);
    margin: 40px 0px 40px 0px;
  }
  .bg-2 {
    background-color: #fffaf2;
    height: 342px;
    width: 100%;
    z-index: -2;
    position: absolute;
    left: 0px;
    transform: skewY(2deg);
    margin: 40px 0px 40px 0px;
  }
  .cardSection {
    padding: 70px 0px 0px 0px !important;
  }
  .OrgImage {
    padding: 62px 0px 50px 0px;
  }
  .dashboard-organizer h3 {
    font-weight: 700;
    font-size: 60px !important;
  }
  .WhyFeis h2 {
    padding: 0px 0px 25px 0px;
}
}

@media screen and (max-width:700px) {
  .registration {
    height: 270px;
}
.create-event .MuiButtonBase-root{
font-size: 16px;
}
}
