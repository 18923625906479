.header-row >td{
    color: #424242 !important; 
    background: #EAEAEA !important;
    font-size:14px !important;
    height:31px !important;
}
.bg-grey{
    background: #EAEAEA !important;
}
.grey-42{
    color: #424242 !important; 
}
.grey-30{
    color: #303030 !important;;
}
.p-25{
    padding: 0.25rem 0.25rem !important;
}
.p-25-15{
    padding: 0.25rem 1.5rem !important;
}
.p-5-25{
    padding: 0.5rem 2.5rem !important; 
}
.p-25-5{
    padding: 0.25rem 0.5rem !important;
}
.w-1{
    width:1% !important;
    }
.w-2{
width:2% !important;
}
.w-3{
width:3% !important;
}
.w-4{
    width:4% !important;
    }
.w-45{
    width:4.5 !important;
}
.w-5{
    width:5% !important;
}
.w-6{
    width:6% !important;    
}
.w-7{
    width:7% !important;
}
.w-8{
width:8% !important;
}
.w-9{
width:9% !important;
}
.w-10{
    width:10% !important;
}
.w-11{
    width:11% !important;
}
.w-12{
    width:12% !important;
}
.w-13{
    width:13% !important;
}
.w-15{
    width:15% !important;
}
.w-18{
    width:18% !important;
}
.w-19{
    width:19% !important;
}
.w-20{
    width:20% !important;
}
.w-25{
    width:25% !important;
}
.w-27{
    width:27% !important;
}
.w-32{
    width:32% !important;
}
.w-33{
    width:33% !important;
}
.w-35{
    width:35% !important;
}
.w-40{
    width:40% !important;
}
.w-50{
    width:50% !important;
}
.w-60{
    width:60% !important;
}
.w-65{
    width:65% !important;
}
.w-70{
    width:70% !important;
}
.br-5{
    border-right: 0.5px solid #DCDCDC !important;
}
.competitionsInnerScroll{
    width:750px !important;
    max-width: 100% !important;
    overflow-x: auto !important;
}
@media only screen and (min-width: 1500px) {
    .competitionsInnerScroll{
        width:950px !important;
    }
}