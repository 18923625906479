.nav-parent-org .dropdown-menu[data-bs-popper] {
  margin-top: -5px;
  padding:5px 0;

}
    .dropdown-menu.show{
      border:none;
      box-shadow:0px 15px 30px -5px rgba(62, 62, 62, 0.1);     
    }
    .dropdown-toggle::after {
      background-image: url("../../Assets/selectArrow.png");
      color: white;
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent
    }
    .dropdown-menu.show a{
      margin: 0;
    }
    .navbar-dashboard{
      color:white !important;
    }
    .navbar-expand-lg {
      height: 100px;
    }
    .navbar-parent {
      display: flex !important;
      justify-content: center;
    }
    .navbar-parent a {
      display: flex;
      align-items: center;
      color: white !important;  
      text-decoration: none;
      width: 100px;
    }
    .navbar-parent a:hover {
      color: #68ad3f;
    }
    .nav-parent-org {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .navbar-toggler {
      border: none !important;
    }
    .navbar-toggler:focus {
      box-shadow: none;
    }
    .dropdown a {
      color: black !important;
      width: 100%;
    }
    .dropdown-toggle {
      color: white !important;
    }
    #offcanvasNavbarDropdown-expand-sm {
      color: white !important;
      text-transform: capitalize !important;
    }
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: transparent;
    }
    .menu {
      padding: 5px 20px 5px 20px;
    }
    
    /* <---------------------------- media query for mpbile devices -------------------------------------> */
    @media only screen and (min-width: 0px) and (max-width: 560px) {
      .parent-mobile-nav {
        max-width: 70% !important;
        padding: 25px;
      }
      .navbar-parent {
        width: 100%;
      }
      #offcanvasNavbarDropdown-expand-sm {
        color: rgb(121, 121, 121) !important;
      }
      .navbar-parent a {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-decoration: none;
        height: 50px;
        width:100%;
        color: rgb(15, 15, 15) !important;
      }
      .nav-parent-org {
        width: 100%;
        padding: 20px 0px 20px 0px;
        display: flex;
        justify-content: space-between;
      }
      .navbar-dashboard{
        color:black !important;
      }
      .dropdown-menu[data-bs-popper] {
        margin-left: -15px;
      }
    }
    
    /* <---------------------------- media query for mpbile devices landscape orientation -------------------------------------> */
    @media only screen and (min-width: 561px) and (max-width: 767px) {
      .container,
      .container-sm {
        max-width: 100% !important;
      }
      .navbar-dashboard{
        color:black !important;
      }
  
    }
    
    /* <---------------------------- media query for ipad pro devices -------------------------------------> */
    @media only screen and (min-width: 767px) and (max-width: 2000px) {
  .parent-header{
    height:8vh;
    padding: 0px 36px !important;
  }
    }
    @media (min-width: 576px){
   .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
    left: unset;
    right: 10px;
}}
    