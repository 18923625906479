.hr-org-body{
    border-bottom: 1.27532px solid #EAEAEA;
}
.hr-org-title{
border-bottom:1.894px solid rgba(140, 174, 120, 0.12);
}
.hi-name{
padding: 0 7%;
color: #424242;
font-size: 30px;
margin-top:2rem;
}
.db-tabs-div{
padding: 0 7%;    
}
.db-tabs-div .MuiButtonBase-root{
padding:0 !important;
min-width:auto !important;
margin-right:30px;
margin-top: 10px;
}
.db-tab{
color: #424242 !important;
opacity: 0.6 !important;
font-size:14px !important;
}
.db-tabs-div .Mui-selected{
color:#68AD3F !important;
opacity: 1 !important;  
}
.db-body{
background-color: #FAFAFA;
padding: 0 7%;
min-height:90vh;
padding-bottom: 100px;
}
.db-org-title{
color:#424242;
font-size:22px;
font-weight: 500;
}
.db-dropdown .dropdown-toggle::after {
display: none;
}
.create-event-opt{
cursor:pointer;
height: 42px;
font-size: 14px;
color: #000000;
}
.db-tab-container .MuiTabPanel-root{
padding: 0 !important;
}
.dashboardPopover  .MuiPopover-paper {
right: 7% !important;
width: 185px !important;
left: auto !important;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
.hi-name{
    padding: 0 5%;
}
.dashboardPopover .MuiPopover-paper {
    right: 5% !important;
}
.db-body{
    padding: 0 5%;
}
.db-tabs-div{
    padding: 0 5%;    
}
.create-event{
    font-size: 12px;
    padding:7px 9px;
}
.db-tabs-div .MuiTabs-indicator{
bottom: 0px !important;
}
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
.hi-name{
    padding: 0 5%;
}
.dashboardPopover .MuiPopover-paper {
    right: 5% !important;
}
.db-body{
    padding: 0 5%;
}
.db-tabs-div{
    padding: 0 5%;    
}
.db-tabs-div .MuiTabs-indicator{
    bottom: 0px !important;
}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .db-body,.db-tabs-div,.hi-name{
        padding: 0 7%;
    }
    .dashboardPopover .MuiPopover-paper {
        right: 7% !important;
    }
}