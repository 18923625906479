.managecard-head {
  font-weight: 400;
  font-size: 14px;
  color: #68ad3f;
}

.managecard-descr {
  font-weight: 400;
  font-size: 12px;
  color: #787878;
}
.web-trans-list-org {
  display: flex;
  align-items: center;
  width: 81%;
}
.managecard,.managecardDisabled {
  width: calc(33.33% - 12px); 
  height: 60px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0px 15px 28px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  cursor: pointer;
}
.managecard{
  background: #ffffff;
}

.managecardDisabled{
  background: #ffffff;
  opacity:0.3;
  pointer-events: none;
}
.managecard-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 18px;
}

.org-body-title {
  font-weight: 500;
  font-size: 22px;
  color: #424242;
}
.manage-img {
  height: 36px;
  width: 36px;
}

.web-btn-org {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #68ad3f;
  min-width: 134px !important;
  overflow-wrap: anywhere;
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .web-trans-list-org {
    display: none;
  }
  .web-btn-org {
    display: none !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 481px) {
  .managecard,.managecardDisabled {
    width: 100%;
  }
  .web-trans-list-org {
    display: none;
  }
  .web-btn-org {
    display: none !important;
  }
  .managecard-head {
    font-size: 12px;
  }
  /* .event-img {
    display: none;
  } */

  .managecard-descr {
    font-size: 10px;
  }
  .org-body-title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .managecard,.managecardDisabled{
    width: calc(50% - 10px);
  }
  .managecard-head {
    font-size: 12px;
  }
  .managecard-descr {
    font-size: 10px;
  }
  .org-body-title {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .managecard,.managecardDisabled {
    width: calc(50% - 10px);
  }
  .managecard-head {
    font-size: 12px;
  }
  .managecard-descr {
    font-size: 10px;
  }
  .org-body-title {
    font-size: 20px;
  }
}
