body {
  margin: 0;
  font-family: "Rubik" !important;
  background-color: #FAFAFA;
}

.error {
  color: red;
  font-size: 12px;
}

.alertbutton {
  z-index: 9999;
  position: relative;
}
.checkbox {
  clip-path: circle(46% at 50% 50%);
}

.qnlogo {
  float: right;
  position: relative;
  bottom: 26vh;
  display: flex;
  right: 10vw;
  height: 73px;
}


.font-weight-bold{
  font-weight: 500;
}
body::-webkit-scrollbar {
  display: none !important;
}
@media only screen and (min-width: 0px) and (max-width: 481px) {
  .mainBtn {
    width: 21%;
    font-size: 16px !important;
    text-align: center;
    padding: 2px 11px !important
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .mainBtn {
    font-size: 18px !important;
    text-align: center;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1200px) {
}
.mainBtn {
  font-size: 18px !important;
  text-align: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1900px) {
  .mainBtn {

    font-size: 15px !important;
    text-align: center;
  }
}
@media screen and (min-width: 1900px) {
  .mainBtn {
    font-size: 18px !important;
    text-align: center;
  }
}
